<template>
    <div>
        {{ formContextData }}
        <div class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('Subscribe Today') }}</Typography>
            <Typography v-if="isAfricaProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Africa Program!')
            }}</Typography>
            <Typography
                v-else-if="isAmericanStatecraftProgram"
                class="description"
                variant="body-display-small"
                as="p"
                >{{ $t('Sign up to receive emails from Carnegie’s American Statecraft Program!') }}</Typography
            >
            <Typography v-else-if="isAsiaProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Asia Program!')
            }}</Typography>
            <Typography v-else-if="isCaliforniaProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie California!')
            }}</Typography>
            <Typography v-else-if="isDemocracyProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Democracy, Conflict, and Governance Program!')
            }}</Typography>
            <Typography v-else-if="isEuropeProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Europe Program!')
            }}</Typography>
            <Typography v-else-if="isGlobalOrderProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Global Order and Institutions Program!')
            }}</Typography>
            <Typography v-else-if="isMiddleEastProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Middle East Program!')
            }}</Typography>
            <Typography v-else-if="isNuclearPolicyProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Nuclear Policy Program!')
            }}</Typography>
            <Typography v-else-if="isRussiaEurasiaProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Russia and Eurasia Program!')
            }}</Typography>
            <Typography v-else-if="isSouthAsiaProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s South Asia Program!')
            }}</Typography>
            <Typography v-else-if="isClimateProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Sustainability, Climate, and Geopolitics Program!')
            }}</Typography>
            <Typography v-else-if="isTechProgram" class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie’s Technology and International Affairs Program!')
            }}</Typography>
            <Typography v-else class="description" variant="body-display-small" as="p">{{
                $t('Sign up to receive emails from Carnegie!')
            }}</Typography>
            <form id="mktoForm_1001"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>Thank you!</strong>
                <p>Check your email for details on your request.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    formContextData: {
        type: Object,
    },
});
const { locale } = useLocale();
const isAfricaProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Africa' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Africa' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isAmericanStatecraftProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'American Statecraft' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'American Statecraft' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isAsiaProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Asia' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Asia' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isCaliforniaProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Carnegie California' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Carnegie California' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isDemocracyProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] ===
            'Democracy, Conflict, and Governance' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Democracy, Conflict, and Governance' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isEuropeProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Europe' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Europe' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isGlobalOrderProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Global Order and Institutions' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Global Order and Institutions' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isMiddleEastProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Middle East' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Middle East' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isNuclearPolicyProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Nuclear Policy' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Nuclear Policy' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isRussiaEurasiaProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'Russia and Eurasia' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Russia and Eurasia' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isSouthAsiaProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] === 'South Asia' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'South Asia' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isClimateProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] ===
            'Sustainability, Climate, and Geopolitics' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Sustainability, Climate, and Geopolitics' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});
const isTechProgram = computed(() => {
    return (
        (props.formContextData.subscribeFormContext.data.syndicatedPrograms[0] ===
            'Technology and International Affairs' &&
            props.formContextData.subscribeFormContext.data.syndicatedCenters[0].title === 'global') ||
        (props.formContextData.subscribeFormContext.data.title === 'Technology and International Affairs' &&
            props.formContextData.subscribeFormContext.data.contentType === 'programs')
    );
});

function formCTWEnAfrica() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'africa' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnAmericanStatecraft() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'americanStatecraft' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnAsia() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'asia' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnCalifornia() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'california' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnDemocracy() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'democracy' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnEurope() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'europe' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnGlobalOrder() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'globalOrder' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnMiddleEast() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'middleEast' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnNuclearPolicy() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'nuclear' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnRussia() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'russia' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnSouthAsia() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'southAsia' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnClimate() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'climate' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEnTech() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'tech' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCTWEn() {
    MktoForms2.loadForm('//813-XYU-422.mktoweb.com', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
onMounted(() => {
    if (isAfricaProgram.value) {
        formCTWEnAfrica();
    } else if (isAmericanStatecraftProgram.value) {
        formCTWEnAmericanStatecraft();
    } else if (isAsiaProgram.value) {
        formCTWEnAsia();
    } else if (isCaliforniaProgram.value) {
        formCTWEnCalifornia();
    } else if (isDemocracyProgram.value) {
        formCTWEnDemocracy();
    } else if (isEuropeProgram.value) {
        formCTWEnEurope();
    } else if (isGlobalOrderProgram.value) {
        formCTWEnGlobalOrder();
    } else if (isMiddleEastProgram.value) {
        formCTWEnMiddleEast();
    } else if (isNuclearPolicyProgram.value) {
        formCTWEnNuclearPolicy();
    } else if (isRussiaEurasiaProgram.value) {
        formCTWEnRussia();
    } else if (isSouthAsiaProgram.value) {
        formCTWEnSouthAsia();
    } else if (isClimateProgram.value) {
        formCTWEnClimate();
    } else if (isTechProgram.value) {
        formCTWEnTech();
    } else {
        formCTWEn();
    }
});
</script>

<style lang="scss" scoped>
@import './././assets/styles/core/marketoForms';
</style>
